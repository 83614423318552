<template>
  <div class="goods">
    <div class="pageMain">
      <!-- 检索 -->
      <el-form
        :model="searchForm"
        :inline="true"
        ref="searchForm"
        label-position="left"
        class="demo-form-inline"
        v-if="searchButton == '1'"
      >
        <el-form-item label="赠品名称">
          <el-input
            v-model="searchForm.name"
            placeholder="请输入赠品名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="赠品类型">
          <el-cascader
            :options="goodsType"
            :show-all-levels="false"
            v-model="goodsTypeId"
            placeholder="请选择赠品类型"
            :props="{
              checkStrictly: true,
              label: 'name',
              value: 'id',
              children: 'childs',
            }"
            clearable
            @change="cascaderChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="赠品单位">
          <el-select v-model="searchForm.unit">
            <el-option
              v-for="item in goodsUnit"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="制造商">
          <el-input
            v-model="searchForm.mfrs"
            placeholder="请输入制造商"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchForm.enabled" placeholder="请选择状态">
            <el-option
              v-for="item in goodsStatus"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchSubmit('searchForm')"
            icon="el-icon-search"
            >搜索</el-button
          >
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="boxMain">
        <p class="boxTitle">赠品列表</p>
        <div class="tableTopBtn clearfix" style="padding: 15px">
          <el-button
            size="mini"
            type="primary"
            @click="handleAdd()"
            v-if="addButton == '1'"
            class="el-icon-plus"
          >
            添加</el-button
          >
          <el-button
            size="mini"
            type="danger"
            :disabled="ids.length == 0"
            @click="handleDel"
            v-if="delButton == '1'"
            class="el-icon-delete"
            >删除</el-button
          >
        </div>
        <template>
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            v-loading="loading"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="icons" label="赠品图片">
              <template slot-scope="scope">
                <div class="demo-image__preview" v-if="scope.row.icons">
                  <el-image
                    style="width: 60px"
                    :src="scope.row.icons"
                    :preview-src-list="scope.row.imgs.split(',')"
                  >
                  </el-image>
                </div>
                <img
                  v-else
                  src="../../assets/img/no_img.png"
                  style="width: 60px"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column prop="name" label="赠品名称" show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              prop="describe"
              label="赠品描述"
              width="130px"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="color" label="颜色"> </el-table-column>
            <el-table-column prop="categoryName" label="类型">
            </el-table-column>
            <el-table-column prop="standard" label="规格" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="model" label="型号"> </el-table-column>
            <el-table-column prop="unit" label="单位"> </el-table-column>
            <!-- <el-table-column prop="num" label="库存"> </el-table-column> -->
            <el-table-column prop="mfrs" label="制造商"> </el-table-column>
            <el-table-column prop="enabled" label="状态">
              <template slot-scope="scope">
                <p v-if="scope.row.enabled == 0" style="color: red">禁用</p>
                <p v-else style="color: #67c23a">启用</p>
              </template>
            </el-table-column>
            <el-table-column
              prop="remark"
              label="备注"
              width="130px"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column align="center" width="280" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleDetails(scope.row)"
                  v-if="searchButton == '1'"
                  >详情</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleEdit(scope.row)"
                  v-if="updateButton == '1'"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleUp(scope.row)"
                  v-if="updateButton == '1'"
                  :disabled="scope.row.enabled == '1'"
                  >启用</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDown(scope.row)"
                  v-if="updateButton == '1'"
                  :disabled="scope.row.enabled == '0'"
                  >禁用</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagesData.currentPage"
              :page-sizes="pagesData.rows"
              :page-size="pagesData.currentRows"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagesData.total"
            >
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    <!-- 新增/编辑 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="60%"
    >
      <el-form
        :model="addEditForm"
        ref="addEditForm"
        :rules="addEditRules"
        :inline="true"
        label-width="120px"
      >
        <el-form-item label="赠品名称" prop="name">
          <el-input
            v-model="addEditForm.name"
            placeholder="请输入赠品名称"
            autocomplete="off"
            style="width: 300px"
            maxlength="50"
            clearable
            :disabled="detailsShow"
            @input="inputGoodsName"
          ></el-input>
        </el-form-item>
        <el-form-item label="赠品类型">
          <el-cascader
            ref="typeCascader"
            style="width: 300px"
            :options="goodsType"
            v-model="goodsTypeId2"
            placeholder="请选择赠品类型"
            :props="{
              checkStrictly: true,
              label: 'name',
              value: 'id',
              children: 'childs',
            }"
            :disabled="detailsShow"
            clearable
            @change="cascaderChange2"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="赠品单位">
          <el-select
            v-model="addEditForm.unitId"
            style="width: 300px"
            @change="unitChange"
            :disabled="detailsShow"
          >
            <el-option
              v-for="item in goodsUnit"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="颜色">
          <el-input
            v-model="addEditForm.color"
            placeholder="请输入颜色"
            style="width: 300px"
            :disabled="detailsShow"
          ></el-input>
        </el-form-item>
        <el-form-item label="型号">
          <el-input
            v-model="addEditForm.model"
            placeholder="请输入型号"
            style="width: 300px"
            :disabled="detailsShow"
          ></el-input>
        </el-form-item>
        <el-form-item label="规格">
          <el-input
            v-model="addEditForm.standard"
            placeholder="请输入规格"
            style="width: 300px"
            :disabled="detailsShow"
          ></el-input>
        </el-form-item>
        <el-form-item label="制造商">
          <el-input
            v-model="addEditForm.mfrs"
            placeholder="请输入制造商"
            style="width: 300px"
            :disabled="detailsShow"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="addEditForm.enabled"
            placeholder="请选择状态"
            style="width: 300px"
            :disabled="detailsShow"
          >
            <el-option
              v-for="item in goodsStatus"
              :key="item.id"
              :label="item.label"
              :value="item.id"
              :disabled="detailsShow"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述">
          <el-input
            type="textarea"
            v-model="addEditForm.describe"
            placeholder="请输入描述"
            style="width: 300px"
            :rows="6"
            :disabled="detailsShow"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="addEditForm.remark"
            placeholder="请输入备注"
            style="width: 300px"
            :rows="6"
            :disabled="detailsShow"
          ></el-input>
        </el-form-item>
        <el-form-item label="赠品图片" prop="imgs" style="display: block">
          <el-upload
            :action="uploadUrl"
            :file-list="fileList"
            :headers="headers"
            multiple
            name="files"
            accept=".jpg,.jpeg,.png"
            v-if="!detailsShow"
            list-type="picture-card"
            :on-success="handleAvatarSuccess"
          >
            <i class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/jpeg/png格式图片。
            </div>
          </el-upload>
          <div class="img_box" v-else>
            <img
              v-for="(item, index) in imgData"
              :key="index"
              :src="item"
              alt=""
              width="100px"
              style="margin-right: 15px"
            />
          </div>
        </el-form-item>
        <el-form-item
          label="拖拽排序"
          v-if="!detailsShow && imgData.length > 1"
        >
          <vuedraggable
            class="flex_left"
            v-model="imgData"
          >
            <div v-for="(item, index) in imgData" :key="index">
              <img
                :src="item"
                alt=""
                width="100px"
                style="margin-right: 15px"
              />
            </div>
          </vuedraggable>
        </el-form-item>
        <el-form-item label="赠品视频" style="display: block">
          <!-- multiple -->
          <el-upload
            class="upload-demo"
            :action="uploadUrl2"
            :headers="headers"
            v-if="!detailsShow"
            :on-success="handleAvatarSuccess2"
            :on-remove="handleRemove2"
            :before-upload="handleFileChange"
            accept=".mp4,.3gp,.m3u8"
            :show-file-list="videoFileShow"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传mp4/3gp/m3u8格式且不能大于50M。
            </div>
          </el-upload>
          <el-button size="small" type="danger" v-if="detailsShow == false && addEditForm.videos" @click="videoDelete" style="margin-bottom:15px;">删除视频</el-button>
          <!-- 视频预览 -->
          <div class="video_box" v-if="addEditForm.videos">
            <video :src="addEditForm.videos" controls width="200px"></video>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="detailsShow == false">
        <el-button @click="addEditCancel">取 消</el-button>
        <el-button type="primary" @click="addEditSubmit('addEditForm')"
          >确 定</el-button
        >
      </div>
      <div slot="footer" class="dialog-footer" v-else>
        <el-button type="primary" @click="addEditCancel">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import vuedraggable from "vuedraggable";
export default {
  name: "goodsIndex",
  components: {
    vuedraggable,
  },
  data() {
    return {
      headers: {
        Authorization: sessionStorage.getItem("Authorization"),
      },
      uploadUrl: this.$axios.defaults.basePath + "/image/AliYunImgUploadList",
      uploadUrl2: this.$axios.defaults.basePath + "/image/AliYunVodUpload",
      loading: false,
      tableData: [],
      addButton: "",
      updateButton: "",
      delButton: "",
      searchButton: "",
      searchForm: {
        name: "",
        categoryId: "",
        unit: "",
        mfrs: "",
        enabled: "",
      },
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      dialogTitle: "",
      dialogFormVisible: false,
      addEditForm: {
        name: "",
      },
      addEditRules: {
        name: [{ required: true, message: "请输入赠品名称", trigger: "blur" }],
        imgs: [
          {
            required: true,
            message: "请至少上传一张赠品图片",
            trigger: "blur",
          },
        ],
      },
      // 复选框选中数组
      ids: [],
      multipleSelection: [],
      // 赠品类型
      goodsType: [],
      // 赠品单位
      goodsUnit: [],
      // 赠品状态
      goodsStatus: [
        {
          label: "禁用",
          id: "0",
        },
        {
          label: "启用",
          id: "1",
        },
      ],
      // 上传图片回显
      fileList: [],
      // 上传图片存储
      imgData: [],
      // 类型ID回显
      goodsTypeId: [],
      goodsTypeId2: [],
      // 查看详情表单禁止输入
      detailsShow: false,
      videoData: [],
      fileList2: [],
      videoFileShow: true,
      // 图片拖拽排序
      groups: [],
    };
  },
  created() {
    this.listEvent();
    this.getGoodsType();
    this.getGoodsUnit();
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "gifts_goods_update") {
        this.updateButton = "1";
      } else if (item.authority == "gifts_goods_add") {
        this.addButton = "1";
      } else if (item.authority == "gifts_goods_delete") {
        this.delButton = "1";
      } else if (item.authority == "gifts_goods_query") {
        this.searchButton = "1";
      }
    });
  },
  methods: {
    // 请求列表
    listEvent() {
      let _this = this;
      _this.loading = true;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/giftsGoods", {
          params: {
            current: _this.pagesData.currentPage,
            size: _this.pagesData.currentRows,
            name: _this.searchForm.name,
            categoryId: _this.searchForm.categoryId,
            unit: _this.searchForm.unit,
            mfrs: _this.searchForm.mfrs,
            enabled: _this.searchForm.enabled,
          },
        })
        .then((res) => {
          console.log(res);
          _this.loading = false;
          if (res.data.errcode == 0) {
            res.data.data.records.forEach((item) => {
              if (item.imgs) {
                item.icons = item.imgs.split(",")[0];
              }
            });
            _this.tableData = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取赠品类型
    getGoodsType() {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
            "/goodsCategory/selectGoodsCategoryTree"
        )
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.goodsType = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 编辑回显赠品类型递归
    familyTree(arr1, id) {
      var temp = [];
      var forFn = function (arr, id) {
        for (var i = 0; i < arr.length; i++) {
          var item = arr[i];
          if (item.id == id) {
            temp.push(item.id);
            forFn(arr1, item.parentId);
            break;
          } else {
            if (item.childs.length != 0) {
              forFn(item.childs, id);
            }
          }
        }
      };
      forFn(arr1, id);
      return temp;
    },
    // 获取赠品单位
    getGoodsUnit() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsUnit/all")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsUnit = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 检索
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    // 检索重置
    resetForm(formName) {
      this[formName] = {};
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
      this.goodsTypeId = [];
      this.goodsTypeId2 = [];
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.listEvent();
    },
    // 表格复选框
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      val.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    // 新增
    handleAdd() {
      this.dialogTitle = "新增";
      this.dialogFormVisible = true;
      this.addEditForm = {};
      this.imgData = [];
      this.fileList = [];
      this.goodsTypeId2 = [];
      this.detailsShow = false;
    },
    // 修改
    handleEdit(row) {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/giftsGoods/getById", {
          params: { id: row.id },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            let data = res.data.data;
            that.detailsShow = false;
            that.fileList = [];
            that.dialogTitle = "编辑";
            that.dialogFormVisible = true;
            data.enabled = data.enabled.toString();
            that.$nextTick(() => {
              that.addEditForm = data;
              // 连级选择器回显
              that.goodsTypeId2 = that
                .familyTree(that.goodsType, data.categoryId)
                .reverse();
              if (data.imgs) {
                let imgArr = data.imgs.split(",");
                that.imgData = imgArr;
                imgArr.forEach((item) => {
                  let json = {
                    url: item,
                  };
                  that.fileList.push(json);
                });
              }
              if (data.videos) {
                let videoArr = data.videos.split(",").map(data => ({data}));
                that.$set(that.addEditForm,'videos',videoArr[0].data)
                that.videoData = videoArr;
                videoArr.forEach((item) => {
                  let json = {
                    url: item.data,
                  };
                  that.fileList2.push(json);
                });
              }
            });
          }
        });
    },
    // 新增/编辑取消
    addEditCancel() {
      window.location.reload();
    },
    // 新增/编辑提交
    addEditSubmit(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url = "";
          if (this.dialogTitle == "新增") {
            url = "/giftsGoods/add";
          } else {
            url = "/giftsGoods/update";
          }
          var videos = [];
          if (this.videoData.length > 0) {
            this.videoData.forEach((item) => {
              videos.push(item.data);
            });
            _this.addEditForm.videos = videos.join(",");
          } else {
            _this.addEditForm.videos = "";
          }
          if (this.imgData.length > 0) {
            this.addEditForm.imgs = this.imgData.join(",");
          }
          console.log(_this.addEditForm);
          this.$axios({
            url: _this.$axios.defaults.basePath + url,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify(_this.addEditForm),
          }).then((res) => {
            if (res.data.errcode == 0) {
              _this.$message({
                message: "操作成功",
                type: "success",
              });
              _this.dialogFormVisible = false;
              _this.listEvent();
              // setTimeout(() => {
              //   window.location.reload();
              // }, 500);
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 批量删除
    handleDel() {
      let _this = this;
      this.$confirm("是否确认删除此赠品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.$axios
            .get(_this.$axios.defaults.basePath + "/giftsGoods/delete", {
              params: {
                ids: _this.ids.join(","),
              },
            })
            .then((res) => {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 多级联动change事件
    cascaderChange(e) {
      this.searchForm.categoryId = e[e.length - 1];
    },
    cascaderChange2(e) {
      this.addEditForm.categoryId = e[e.length - 1];
    },
    // 单位选择
    unitChange(e) {
      this.goodsUnit.forEach((item) => {
        if (item.id == e) {
          this.addEditForm.unit = item.name;
        }
      });
    },
    // 详情
    handleDetails(row) {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/giftsGoods/getById", {
          params: { id: row.id },
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            let data = res.data.data;
            that.detailsShow = true;
            that.fileList = [];
            that.dialogTitle = "详情";
            that.dialogFormVisible = true;
            data.enabled = data.enabled.toString();
            that.$nextTick(() => {
              that.addEditForm = data;
              // 连级选择器回显
              that.goodsTypeId2 = that
                .familyTree(that.goodsType, data.categoryId)
                .reverse();
              if (data.imgs) {
                let imgArr = data.imgs.split(",");
                that.imgData = imgArr;
                imgArr.forEach((item) => {
                  let json = {
                    url: item,
                  };
                  that.fileList.push(json);
                });
              }
            });
          }
        });
    },
    // 启用
    handleUp(row) {
      let _this = this;
      row.enabled = "1";
      this.$axios({
        url: _this.$axios.defaults.basePath + "/giftsGoods/update",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(row),
      }).then((res) => {
        if (res.data.errcode == 0) {
          _this.$message({
            message: "操作成功",
            type: "success",
          });
          _this.listEvent();
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
        }
      });
    },
    // 禁用
    handleDown(row) {
      let _this = this;
      row.enabled = "0";
      this.$axios({
        url: _this.$axios.defaults.basePath + "/giftsGoods/update",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(row),
      }).then((res) => {
        if (res.data.errcode == 0) {
          _this.$message({
            message: "操作成功",
            type: "success",
          });
          _this.listEvent();
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: "warning",
          });
        }
      });
    },
    //上传图片事件
    handleAvatarSuccess(res, file) {
      if (res.errcode == 1) {
        this.$message.error("上传失败");
        return;
      }
      this.imgData.push(res);
      console.log(this.imgData);
      if (this.imgData.length > 0) {
        this.$set(this.addEditForm,'imgs',this.imgData.join(","))
        // this.addEditForm.imgs = this.imgData.join(",");
      }
    },
    // 上传图片删除
    handleRemove(file) {
      console.log(file);
      let imgArr = [];
      this.fileList = [];
      let url = file.response ? file.response : file.url;
      this.imgData.forEach((item) => {
        if (url != item) {
          imgArr.push(item);
          this.fileList.push({ url: item });
        }
      });
      this.imgData = imgArr;
      if (this.imgData.length > 0) {
        this.addEditForm.imgs = this.imgData.join(",");
      } else {
        this.addEditForm.imgs = "";
      }

      // let ind = "";
      // this.fileList.forEach((item, index) => {
      //   if (file.response == item.url) {
      //     ind = index;
      //   }
      // });
      // this.fileList.splice(ind, 1);
    },
    //上传视频事件
    handleAvatarSuccess2(res, file) {
      console.log(res, file);
      if (res.errcode == 1) {
        this.$message.error("上传失败");
        return;
      }
      this.videoData = [];
      this.fileList2 = [];
      this.videoData.push(res);
      this.fileList2.push({ url: res.data });
      this.$set(this.addEditForm,'videos',res.data)
      this.videoFileShow = false;
    },
    // 上传视频删除
    handleRemove2(file) {
      let videoArr = [];
      let url = file.url ? file.url : file.response;
      this.videoData.forEach((item) => {
        if (url != item) {
          videoArr.push(item);
        }
      });
      this.videoData = videoArr;
      let ind = "";
      this.fileList2.forEach((item, index) => {
        if (url == item.url) {
          ind = index;
        }
      });
      this.fileList2.splice(ind, 1);
    },
    // 限制视频大小
    handleFileChange(file) {
      return new Promise((resolve, reject) => {
        const isLt5M = file.size / 1024 / 1024 <= 50;
        if (!isLt5M) {
          this.$message.error("上传视频大小不能超过 50MB!");
          return reject(false);
        } else {
          return resolve(true);
        }
      });
    },
    inputGoodsName(e) {
      console.log(e.length);
      if (e.length >= 50) {
        this.$message({
          message: "赠品名称不能大于50个字符",
          type: "warning",
        });
      }
    },
    // 视频删除
    videoDelete(){
      this.$confirm('是否确定执行此操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          });
          this.videoData = [];
          this.$set(this.addEditForm,'videos','');
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });

    },
  },
};
</script>

<style scoped>
</style>
